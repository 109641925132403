
import SideMenu from "@/components/base/menu/SideMenu.vue";
import { IonRouterOutlet, IonSplitPane } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useWindowSize } from "@/composable/window";

import { useRoute } from "vue-router";

const noSideBarRoutes = ["/login", "/signup", "/error"];

export default defineComponent({
  name: "PrivateLayout",
  components: {
    IonRouterOutlet,
    IonSplitPane,
    SideMenu,
  },
  setup() {
    useWindowSize();
    const route = useRoute();
    const splitPaneActive = computed(() => {
      return noSideBarRoutes.includes(route.path.toLowerCase());
    });

    return {
      splitPaneActive,
    };
  },
});
