import Repository from "./Repository";
import authHeader from "@/services/auth-header"; 

const resource = "/operator";
export default {
  assign(userId) {
    return Repository.post(`${resource}/assign/${userId}`,{ headers: authHeader() });

  },

  getUsers(page = 1, sort = "", direction = "", filters = [], search='') {
    let url = `${resource}/get_operator_users?page=${page}`;
    if (sort) url += `&sort=${sort}`;
    if (direction) url += `&sortDirection=${direction}`;
    if (filters.length) url += "&filters=" + JSON.stringify(filters);
    if (search) url += `&search=${search}`;
    return Repository.get(url,{ headers: authHeader() });

  },

};
