import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 3,
  class: "flex-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": _ctx.item.href,
    lines: "none",
    detail: "false",
    class: _normalizeClass(["container hydrated", [
      _ctx.active
        ? 'text-secondary'
        : 'text-indigo-100 hover:primaryColor hover:bg-opacity-75',
      _ctx.item.mobileOnly ? 'md:hidden lg:hidden xl:hidden 2xl:hidden' : '',
      'group flex mt-1 items-center px-2 py-2 text-lg font-medium rounded-md cursor-pointer',
    ]])
  }, {
    default: _withCtx(() => [
      (_ctx.item.fa)
        ? (_openBlock(), _createBlock(_component_fa_icon, {
            key: 0,
            icon: _ctx.item.icon,
            class: _normalizeClass([
      _ctx.active
        ? 'flex-shrink-0 w-6 h-6 mr-3 text-secondary'
        : 'flex-shrink-0 w-6 h-6 mr-3 text-white',
    ]),
            "aria-hidden": "true"
          }, null, 8, ["icon", "class"]))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.icon), {
            key: 1,
            class: "flex-shrink-0 w-6 h-6 mr-3 text-white",
            "aria-hidden": "true"
          })),
      (_ctx.item.direct)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            href: _ctx.item.url,
            class: "flex-1 cursor-pointer"
          }, _toDisplayString(_ctx.t(_ctx.item.name)), 9, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t(_ctx.item.name)), 1))
    ]),
    _: 1
  }, 8, ["router-link", "class"]))
}