
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonBackButton,
    IonPage,
    IonToolbar,
} from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useUserStore } from "@/store/user";
//import BaseBanner from "@/components/base/BaseBanner.vue";
import Footer from "@/components/homepage/Footer.vue";
export default defineComponent({
    name: "BasePage",
    props: {
        title: String,
        defaultUrl: {
            type: String,
            default: "/",
        },
        showBack: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonBackButton,
        IonPage,
        IonToolbar,
        Footer,
        //BaseBanner,
    },
    setup() {
        const userStore = useUserStore();
        const user = computed(() =>
            userStore.user ? userStore.user.user : null
        );

        const handleLogout = async () => {
            const { data, error } = await userStore.logout();            
        };

        return {
            user,
            userStore,
            handleLogout,
        };
    },
});
