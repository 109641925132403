import authHeader from "@/services/auth-header";

import axios from "axios";

// You can use your own logic to set your local or production domain
const baseDomain = process.env.VUE_APP_BACKEND_URL;
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}/api`;

const instance = axios.create({
  baseURL,
});
class AuthService {
  login(user) {
    return instance
      .post("/login", { email: user.email, password: user.password })
      .then((response) => {
        //localStorage.setItem('user', JSON.stringify(response.data));
        return response.data;
      });
  }
  forgetPassword(user) {
    return instance
      .post("/forgot-password", { email: user.email })
      .then((response) => {
        //localStorage.setItem('user', JSON.stringify(response.data));
        return response;
      });
  }
  resetPassword(user, token) {
    return instance
      .post("/reset-password", {
        email: user.email,
        password: user.password,
        password_confirmation: user.confirmPassword,
        token: token,
      })
      .then((response) => {
        //localStorage.setItem('user', JSON.stringify(response.data));
        return response;
      });
  }

  logout() {
    return instance
      .post("/logout", null, { headers: authHeader() })
      .then((response) => {
        //localStorage.setItem('user', JSON.stringify(response.data));
        return response.data;
      });
  }
}

export default new AuthService();
