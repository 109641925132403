import { defineStore } from "pinia";
import { useUserStore } from "@/store/user";
import { toRaw } from "vue";

import useBackendFetch from "@/repositories/BackendFetch";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useWearableStore = defineStore("wearable", {
  state: () => {
    const wearables = [];
    const page = 1;
    const sensors = [];
    const pagination = null;

    return {
      wearables,
      sensors,
      page,
      pagination,
    };
  },
  actions: {
    async get(refreshData) {
      const userStore = useUserStore();
      const user = userStore.user.user;
      const { data } = await useBackendFetch(
        "samples/" + user.id + "?page=" + this.page
      )
        .get()
        .json();
      if (Array.isArray(data.value.wearables)) {
        if (refreshData) this.wearables = data.value.wearables;
        else this.wearables = this.wearables.concat(data.value.wearables);
        this.pagination = data.pagination;
      } else data.value = [];
      return data.value;
    },
    async getDaySample(sensorName, date) {
      const userStore = useUserStore();
      const user = userStore.user.user;
      const { data } = await useBackendFetch(
        "samples/sensor?sensorName=" +
          sensorName +
          "&date=" +
          date +
          "&userId=" +
          user.id
      )
        .get()
        .json();
      return data.value;
    },
    async getSensors() {
      const { data } = await useBackendFetch("samples/sensors").get().json();
      this.sensors = data.value;
    },

    async getEcgBlocks(date) {
      const userStore = useUserStore();
      const user = userStore.user.user;
      const { data } = await useBackendFetch(
        "samples/sensors/ecg/blocks?date=" + date + "&userId=" + user.id
      )
        .get()
        .json();
      return data.value;
    },
    async update(values) {
      const { data } = await useBackendFetch("settings").post(values).json();
      this.settings = data.value;
      localStorage.setItem("user_settings", JSON.stringify(data.value));
    },
    getSensorColor(sensor) {
      const l = toRaw(this.sensors.find((o) => o.name === sensor));
      return l?.color;
    },
    getSensorSettings(sensor) {
      const l = toRaw(this.sensors.find((o) => o.name === sensor));
      return l?.settings;
    },
  },

  getters: {
    //
  },
});
