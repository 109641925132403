import Repository from "./Repository";

import authHeader from "@/services/auth-header";

const resource = "/dives";

export default {
  getByUser(
    userId,
    page = 1,
    sort = "",
    direction = "",
    filters = [],
    dates = {},
    limit = 12
  ) {
    let url = `${resource}/user/${userId}?page=${page}`;
    if (sort) url += `&sort=${sort}`;
    if (direction) url += `&sortDirection=${direction}`;
    if (filters.length) url += "&filters=" + JSON.stringify(filters);
    if (dates.startDate) url += "&dates=" + JSON.stringify(dates);
    if (limit) url += `&perPage=${limit}`;
    return Repository.get(url, { headers: authHeader() });
  },
  getDCSDives(
      page = 1,
      limit = 12,
      sort = "",
      direction = ""
  ) {
    let url = `${resource}?page=${page}`;
    if (sort) url += `&sort=${sort}`;
    if (direction) url += `&sortDirection=${direction}`;
    if (limit) url += `&perPage=${limit}`;
    return Repository.get(url, { headers: authHeader() });
  },

  get(id, gfs) {
    const { hi, low } = gfs;
    return Repository.get(`${resource}/${id}?gf_hi=${hi}&gf_low=${low}`, {
      headers: authHeader(),
    });
  },
  deleteDive(diveId) {
    return Repository.delete(`${resource}/${diveId}`, {
      headers: authHeader(),
    });
  },
  postDive(data, userId) {
    const config = { headers: authHeader() };
    config.headers["Content-Type"] = "multipart/form-data";
    return Repository.post(`${resource}/upload/${userId}`, data, config);
  },
  saveDetail(data, diveId) {
    return Repository.post(
      `${resource}/${diveId}`,
      { data },
      { headers: authHeader() }
    );
  },
  saveAdvLab(data, diveId) {
    return Repository.post(
      `${resource}/adv-lab/${diveId}`,
      { data },
      { headers: authHeader() }
    );
  },

  saveTank(data, diveId) {
    return Repository.post(`${resource}/${diveId}/tank`, data, {
      headers: authHeader(),
    });
  },
  savePPO2(data, diveId) {
    return Repository.post(`${resource}/${diveId}/ppo2`, data, {
      headers: authHeader(),
    });
  },
  saveDiluent(data, diveId) {
    return Repository.post(`${resource}/${diveId}/diluent`, data, {
      headers: authHeader(),
    });
  },
  deleteTank(data, diveId) {
    return Repository.delete(`${resource}/${diveId}/tank`, {
      headers: authHeader(),
      data,
    });
  },
  deletePPO2(data, diveId) {
    return Repository.delete(`${resource}/${diveId}/ppo2`, {
      headers: authHeader(),
      data,
    });
  },
};
