import Repository from "./Repository";

import authHeader from "@/services/auth-header"; 

const resource = "/vouchers";

export default {
  list(page = 1, perPage = 10) {
    return Repository.get(`${resource}?page=${page}&per_page=${perPage}`,{ headers: authHeader()});
  },
  store(data, id) {
    let url = `${resource}`;
    if (id) {
      url += `/${id}`;
    }
    return Repository.post(url, JSON.stringify(data),{ headers: authHeader()});
  },
  renew(id) {
    const newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() + 1);
    let  data = {data: { expires_at: newDate.toISOString() }};
    return Repository.post(`${resource}/${id}`, JSON.stringify(data), { headers: authHeader()});
  },
  delete(id) {
    return Repository.delete(`${resource}/${id}`,{ headers: authHeader()});
  },
};
