const isNew = (dive) => {
  const infos = dive.details?.infos;
  const completed =
    infos !== undefined &&
    infos?.suit &&
    infos?.weights &&
    infos?.current &&
    infos?.visibility &&
    infos?.feel &&
    infos?.howlike &&
    infos?.purpose &&
    infos?.workload &&
    infos?.thermalConfort;
  return !completed;
};

export { isNew };
