import Repository from "./Repository";

import authHeader from "@/services/auth-header"; 

const resource = "/roles";

export default {
  list() {
    return Repository.get(`${resource}`,{ headers: authHeader()});
  },
  store(data) {
    return Repository.post(`${resource}`, JSON.stringify(data),{ headers: authHeader()});
  },
  updateUserRoles(data,userId) {
    return Repository.post(`${resource}/user/${userId}`, JSON.stringify(data),{ headers: authHeader()});
  },
  delete(id) {
    return Repository.delete(`${resource}/${id}`,{ headers: authHeader()});
  },
};
