
import { defineComponent } from "vue";
import { IonRouterOutlet } from "@ionic/vue";
import { useWindowSize } from "@/composable/window";

export default defineComponent({
  name: "PublicLayout",
  components: {
    IonRouterOutlet,
  },
  setup() {
    useWindowSize();
  },
});
