<template>
    <ion-footer>
        <ion-toolbar color="primary">
            <div class="py-10 px-4 sm:px-6  md:items-center lg:px-4 text-white text-xl font-medium">
                <ion-grid>
                    <ion-row>
                        <ion-col size="4" size-sm="4" size-md="3" size-xl="2">
                            <span class="cursor-pointer hover:underline" @click="router().push('/')">
                                <img src="/assets/logo_footer.svg" class="h-10 mr-auto" />
                              <br><br><span class="font-black text-2xl">DANA SRL</span>
                            </span>
                        </ion-col>
                        <ion-col size="4" size-sm="8" size-md="6" size-lg="5" size-xl="6" class="border-left pl-3">
                            <div><ion-icon color="secondary" name="location"></ion-icon>C.da Padune, 11<br/><span class="pl-4">64026 Roseto
                              Degli Abruzzi - TE</span></div>
                            <div class="pt-4"><ion-icon color="secondary" name="information"></ion-icon>C.F. e P. Iva
                                02160740672</div>
                          <div><ion-icon color="secondary" name="call"></ion-icon> Tel: <a class="cursor-pointer hover:underline" href="tel:+39-085-893-0333">+39-085-893-0333</a></div>
                        </ion-col>
                        <ion-col size="4" size-md="3" size-sm="12" size-lg="4" size-xl="4" class="ion-align-self-end text-right">
                            <span class="cursor-pointer hover:underline" @click="router().push('/privacy')">Privacy Policy</span> <span class="text-secondary"> | </span>
                            <a class="cursor-pointer iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Cookie Policy" target="_blank" href="https://www.iubenda.com/privacy-policy/68598572/cookie-policy">Cookie Policy</a>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-toolbar>
    </ion-footer>
</template>


<script>
import {IonFooter, IonToolbar, IonIcon, IonCol, IonRow, IonGrid} from '@ionic/vue';
import router from "@/router";
import { addIcons } from 'ionicons';
import { call, location, information, mail } from 'ionicons/icons';

addIcons({
    call: call,
    location: location,
    information: information,
    mail: mail
});

export default {
    methods: {
        router() {
            return router
        }
    },
    components: { IonFooter, IonToolbar, IonIcon, IonCol, IonRow, IonGrid },
};
</script>
<style scoped>
.border-left {
    border-left: 2px solid var(--ion-color-secondary);
}
</style>