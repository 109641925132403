
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { IonItem } from "@ionic/vue";
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: "MenuItem",
  components: {
    IonItem,
  },
  props: ["item"],
  setup(props) {
    const {t}=useI18n();
    const router = useRouter();
    const active = computed(() =>
      router.currentRoute.value.path.startsWith(props.item.href)
    );
    return {
      active,
      t,
    };
  },
});
