<template>
  <IonApp>
    <loader />
    <public v-if="publicPage"></public>
    <private v-else></private>
  </IonApp>
</template>

<script>
import Private from "@/components/layout/Private.vue";
import Public from "@/components/layout/Public.vue";
import Loader from "@/components/base/Loader.vue";
import { defineComponent, computed, watchEffect } from "vue";
import { IonApp } from "@ionic/vue";
import { useRoute } from "vue-router";
//import { useSettingsStore } from "@/store/settings";
const noSideBarRoutes = ["/login", "/signup", "/error"];

export default defineComponent({
  name: "App",
  components: {
    Private,
    Public,
    IonApp,
    Loader,
  },
  setup() {
    const route = useRoute();
    const publicPage = computed(() => {
      return noSideBarRoutes.includes(route.path.toLowerCase());
    });
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      //document.body.classList.toggle("dark", shouldAdd);
      document.body.classList.toggle("dark", false);
    }

    //const settingsStore = useSettingsStore();
    //const settings = computed(() => useSettingsStore().settings);
    /*   watchEffect(() => {
      if (settings.value) {
        document.body.classList.toggle("dark", settings.value.dark_mode);
      } else document.body.classList.toggle("dark", true);
    }); */
    return {
      publicPage,
    };
  },
});
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.ion-page {
  @apply bg-primary/[.58];
}
.textColor-main {
  @apply text-[#fefefe];
}
.textColor-inverted {
  @apply text-white;
}
.cardBackground {
  @apply text-primary text-font-color bg-[#fefefe] rounded-lg;
}

.primaryColor {
  @apply bg-primary border-primary hover:border hover:border-secondary hover:text-primary !important;
}
.textColor-primary {
  @apply text-primary;
}
.border-primary {
  @apply border-blue-500;
}
.buttonInactive-primary {
  @apply bg-transparent border-2 border-primary text-primary !important;
}
.buttonActive-primary {
  @apply primaryColor text-white cursor-pointer font-medium hover:bg-transparent !important;
}

.successColor {
  @apply bg-green-600 hover:bg-green-700 focus:ring-green-500 !important;
}
.buttonActive-success {
  @apply successColor text-white;
}

.softColor {
  @apply bg-gray-200 hover:bg-gray-300 focus:ring-gray-400 !important;
}
.buttonActive-soft {
  @apply softColor text-gray-700;
}

.dangerColor {
  @apply bg-danger hover:bg-transparent hover:border border-danger hover:text-danger !important;
}
.buttonInactive-danger {
  @apply bg-red-50 text-red-700;
}
.buttonActive-danger {
  @apply dangerColor text-white cursor-pointer;
}
.litepie-datepicker {
  @apply mt-[55px] md:mt-0;
}

/* Set variables for all modes */
:root {
  /* Set the font family of the entire app */
  /* --ion-font-family: 'Avenir'; */
  /* //--ion-color-dark: white;
  //--ion-background-color: white; */
  --ion-font-family: 'Montserrat';
}
.ios,.md {
  /* //--ion-text-color: #000; */
  /* --ion-font-family: 'Avenir'; */
  --ion-background-color: white;
  --ion-font-family: 'Montserrat';
}
ion-popover > ion-content {
  --ion-color-dark: black;
  --ion-text-color: black;
}

@media (max-width: 425px) {
  ion-grid {
    --ion-grid-columns: 3;
  }
}
</style>
