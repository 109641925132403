import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-135b30ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0" }
const _hoisted_2 = { class: "inline-flex" }
const _hoisted_3 = {
  href: "/faq",
  class: "inline-flex items-center px-4 py-2 text-base uppercase font-medium text-white hover:text-secondary cursor-pointer"
}
const _hoisted_4 = { class: "inline-flex" }
const _hoisted_5 = {
  href: "/contact",
  class: "inline-flex items-center px-4 py-2 text-base uppercase font-medium text-white hover:text-secondary cursor-pointer"
}
const _hoisted_6 = { class: "flex items-center px-4 py-2" }
const _hoisted_7 = { class: "text-2xl text-secondary group-hover:text-white flex items-center" }
const _hoisted_8 = { class: "text-sm ml-2 font-medium text-white" }
const _hoisted_9 = { class: "textColor-main dark:text-font-color-inverte min-h-full bg-primary/[.58] ion-padding pb-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, {
            color: "primary",
            mod: "md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { "auto-hide": false }),
                  (_ctx.showBack)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, {
                        key: 0,
                        "default-href": _ctx.defaultUrl
                      }, null, 8, ["default-href"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, [
                  _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t("homepage.faq")), 1)
                ]),
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t("homepage.contact")), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_ion_icon, { name: "person" })
                  ]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.user.firstname), 1),
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args))),
                    class: "text-2xl ml-2 text-secondary cursor-pointer group-hover:text-white flex items-center"
                  }, [
                    _createVNode(_component_ion_icon, { name: "log-out-outline" })
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _createVNode(_component_Footer, { class: "bg-primary/[.58]" })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}