import { defineStore } from "pinia";
import { useUserStore } from "@/store/user";
import { toRaw } from "vue";

import useBackendFetch from "@/repositories/BackendFetch";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useTrainingStore = defineStore("training", {
  state: () => {
    const sessions = [];
    const currentSession = null;
    const page = 0;
    return {
      sessions,
      currentSession,
      page,
    };
  },
  actions: {
    async saveSession(sessionData) {
      const { data } = await useBackendFetch("training")
        .post(sessionData)
        .json();
      this.sessions.unshift(data.value);
    },
    setCurrentSession(session) {
      this.currentSession = session;
    },
    resetCurrentSession() {
      this.currentSession = null;
    },
    async getSessions() {
      this.page++;

      const userStore = useUserStore();
      const user = userStore.user.user;
      const { data } = await useBackendFetch(
        "training/" + user.id + "?page=" + this.page
      )
        .get()
        .json();
      if (Array.isArray(data.value)) this.sessions.push(...data.value);
      else data.value = [];
      return data.value;
    },
    async getSession(id) {
      const { data } = await useBackendFetch("training/session/" + id)
        .get()
        .json();

      this.currentSession = data.value;
    },
  },

  getters: {
    //
  },
});
