import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import FontAwesomeIcon from "@/components/base/FontAwesomeIcon.vue";

library.add(fas, far, faTwitter, faInstagram);

export { FontAwesomeIcon };
