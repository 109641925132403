import Repository from "./Repository";
import authHeader from "@/services/auth-header"; 

const resource = "/subscriptions";
export default {

  get() {
    return Repository.get(`${resource}/load`,{ headers: authHeader() });
  },
  getInvoices() {
    return Repository.get(`${resource}/invoices`,{ headers: authHeader() });
  },

  getStripeIntent() {
    return Repository.get(`${resource}/setup-intent`,{ headers: authHeader() });
  },
  savePaymentMethod(paymentMethod) {
    return Repository.post(`${resource}/payment-method`,{payment_method:paymentMethod},{ headers: authHeader()});

  },
  getPaymentMethod() {
    return Repository.get(`${resource}/payment-method`,{ headers: authHeader()});

  },
  deletePaymentMethod(id) {
    return Repository.delete(`${resource}/payment-method/${id}`,{ headers: authHeader()});

  },
  updateDefaultPaymentMethod(id) {
    return Repository.post(`${resource}/default-payment-method`,{payment_method_id:id},{ headers: authHeader()});

  },
  activate(id) {
    return Repository.post(`${resource}/activate/${id}`,{},{ headers: authHeader()});

  }

};
