import { createFetch } from '@vueuse/core';
import { useUserStore } from '@/store/user';
import { useToast } from 'vue-toastification';
import router  from '@/router'

const baseDomain = process.env.VUE_APP_BACKEND_URL;
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}/api`;
const useBackendFetch = createFetch({
  baseUrl: baseURL,
  options: {
    async beforeFetch({ options }) {
      options.headers = {
        ...options.headers,
        Accept : "application/json"
        };
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.token)
        options.headers = {
          ...options.headers,
          Authorization : `Bearer ${user.token}`
          };

      return { options };
    },
  },
  async onFetchError(ctx) {
    if (ctx.statusCode === 401) {
      const userStore = useUserStore();
      await userStore.resetUser();
      const toast = useToast();
      toast.error('You are not authorized to perform this action');
    }
    else if( ctx.statusCode === 403){
      const  toast= useToast();
      toast.error("You are not authorized to perform this action")
      router.push('/dashboard')
    }

    return ctx;
  },
  fetchOptions: {
    mode: 'cors',
  },
});

export default useBackendFetch;
